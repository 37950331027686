export default {
  ROOT: '/',
  LOGIN: '/login',
  MFA: '/mfa',
  MFAManagement: '/mfa-management',
  USERS: '/users',
  ADD_USER: '/users/new',
  MANAGE_USER: '/users/manage/:id',
  MODIFY_USER: '/users/:id',
  USER_MANAGEMENT_SYS: '/management',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  RECOVER_PASSWORD: '/recover-password',
  RESET_PASSWORD: '/reset-password',
  SITES: '/sites',
  ADD_SITE: '/sites/new',
  MODIFY_SITE: '/sites/:id',
  SCREENS: '/screens',
  ORGANIZATIONS: '/organizations',
  ADD_ORGANIZATION: '/organizations/new',
  MODIFY_ORGANIZATION: '/organizations/:id',
  SITEINFO: '/:siteId/information/:siteName',
  SCREENINFO: '/:siteId/screen/:siteName/:screenId',
  POIS: '/:siteId/information/:siteName/pois',
  ADD_POI: '/:siteId/information/:siteName/pois/new',
  MODIFY_POI: '/:siteId/information/:siteName/pois/:id',
  CATEGORIES: '/:siteId/information/:siteName/categories',
  ADD_INFORMATION: '/:siteId/information/:siteName/information/new',
  MODIFY_INFORMATION: '/:siteId/information/:siteName/information/:id',
  INFORMATION: '/:siteId/information/:siteName/information',
  INFORMATION_CATEGORIES:
    '/:siteId/information/:siteName/informationCategories',
  ADD_CATEGORY: '/:siteId/information/:siteName/categories/new',
  MODIFY_CATEGORY: '/:siteId/information/:siteName/categories/:id',
  MEDIACATEGORIES: '/:siteId/information/:siteName/media-categories',
  ADD_MEDIA_CATEGORY: '/:siteId/information/:siteName/media-categories/new',
  MODIFY_MEDIA_CATEGORY: '/:siteId/information/:siteName/media-categories/:id',
  MEDIA: '/:siteId/information/:siteName/media',
  ADD_MEDIA: '/:siteId/information/:siteName/media/new',
  MODIFY_MEDIA: '/:siteId/information/:siteName/media/:id',
  // ADD_FEEDBACK: '/:siteId/information/:siteName/feedback/new',
  // RESPONDENT_FEEDBACK: '/:siteId/information/:siteName/respondent/:id',
  SCREENSHOTS: '/:siteId/information/:siteName/screenshots/:mapId',
  // MODIFY_FEEDBACK: '/:siteId/information/:siteName/feedback/:id',
  MEDIA_CALENDAR: '/:siteId/information/:siteName/media/:id/calendar',
  CALENDAR: '/calendar/:siteId',
  ANALYTICS: '/:siteId/information/:siteName/analytics',
  PATHS: '/:siteId/:siteName/maps/:mapId/:mapName/paths',
  // MAPS: '/:siteId/information/:siteName/maps',
  // ADD_MAP: '/:siteId/information/:siteName/maps/new',
  // MODIFY_MAP: '/:siteId/information/:siteName/maps/:id',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  SUBMENU_3: '/submenu3',
  BUGS: '/bugs',
  LOGS: '/logs',
  AUTH_LOGS: '/authlogs',
  ROLES: '/roles',
  ADD_ROLES: '/roles/new',
  MODIFY_ROLES: '/roles/:id',
  PANEL: '/panel',
  PANEL_USER: '/paneluser',
  PANEL_ROLE: '/panel/:id',
  SCREEN_USERS: '/screen-users',
  SCREEN_USER: '/screen-users/new',
  MODIFY_SCREEN_USER: '/screen-users/:id',
};
