import { Dropdown, Menu, Tooltip } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'react-redux';
import './Information.scss';

const CategoryDropdown = ({
  history,
  setTempSelectedCategory,
  handleEditCategory,
  handleDeleteCategory,
  categories,
  modalOpen,
  handleSetPlaylist,
  tempSelectedCategory,
  handleNew,
  filter,
  title,
}) => {
  const { role } = useSelector(
    (state) => ({
      role: state.auth.userData.role,
    }),
    shallowEqual
  );
  return (
    <div
      className={`playlist-dropdown ${
        modalOpen ? 'playlist-dropdown-open' : 'playlist-dropdown-closed'
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <ul>
        {role === 'superadmin' && (
          <li className="header" onClick={handleNew}>
            <i className="feather-plus" />
            {`Add New ${title ?? 'Category'}`}
          </li>
        )}
        {filter && (
          <li>
            <div
              className={`radio ${
                tempSelectedCategory === 'all' ? 'active' : ''
              }`}
              onClick={() => setTempSelectedCategory('all')}
            >
              {tempSelectedCategory === 'all' && (
                <div className="radio-checked" />
              )}
            </div>
            Show All
          </li>
        )}

        {categories.map((cat) => {
          return (
            <li key={cat._id}>
              <div className="playlist-filter">
                <div className="playlist-filter-radio">
                  <div
                    className={`radio ${
                      tempSelectedCategory === cat._id ? 'active' : ''
                    }`}
                    onClick={() => setTempSelectedCategory(cat._id)}
                  >
                    {tempSelectedCategory === cat._id && (
                      <div className="radio-checked" />
                    )}
                  </div>
                  {cat.name}
                </div>
                {handleEditCategory &&
                  handleDeleteCategory &&
                  role === 'superadmin' && (
                    <Tooltip title="Options">
                      <Dropdown
                        trigger="click"
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="edit"
                              onClick={() => handleEditCategory(cat)}
                            >
                              <i className="feather-edit mr-2" />
                              Edit
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="delete"
                              onClick={() => handleDeleteCategory(cat)}
                            >
                              <i className="feather-trash-2 mr-2" />
                              Delete
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <button>
                          <i className="feather-more-horizontal" />
                        </button>
                      </Dropdown>
                    </Tooltip>
                  )}
              </div>
            </li>
          );
        })}
        <li className="playlist-button" onClick={handleSetPlaylist}>
          {`Set ${title ?? 'Category'}`}
        </li>
      </ul>
    </div>
  );
};

export default CategoryDropdown;
