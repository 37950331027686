import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const SCREENS_FETCH_DATA_INIT = createAction('SCREENS_FETCH_DATA_INIT');
export const SCREENS_FETCH_DATA_SUCCESS = createAction(
  'SCREENS_FETCH_DATA_SUCCESS'
);
export const SCREENS_FETCH_DATA_FAIL = createAction('SCREENS_FETCH_DATA_FAIL');

export const SCREENS_DELETE_SCREEN_INIT = createAction(
  'SCREENS_DELETE_SCREEN_INIT'
);
export const SCREENS_DELETE_SCREEN_SUCCESS = createAction(
  'SCREENS_DELETE_SCREEN_SUCCESS'
);
export const SCREENS_DELETE_SCREEN_FAIL = createAction(
  'SCREENS_DELETE_SCREEN_FAIL'
);

export const SCREENS_CLEAR_DATA = createAction('SCREENS_CLEAR_DATA');

export const SCREENS_CREATE_SCREEN_INIT = createAction(
  'SCREENS_CREATE_SCREEN_INIT'
);
export const SCREENS_CREATE_SCREEN_SUCCESS = createAction(
  'SCREENS_CREATE_SCREEN_SUCCESS'
);
export const SCREENS_CREATE_SCREEN_FAIL = createAction(
  'SCREENS_CREATE_SCREEN_FAIL'
);

export const SCREENS_MODIFY_SCREEN_INIT = createAction(
  'SCREENS_MODIFY_SCREEN_INIT'
);
export const SCREENS_MODIFY_SCREEN_SUCCESS = createAction(
  'SCREENS_MODIFY_SCREEN_SUCCESS'
);
export const SCREENS_MODIFY_SCREEN_FAIL = createAction(
  'SCREENS_MODIFY_SCREEN_FAIL'
);

export const SCREENS_CLEAN_UP = createAction('SCREENS_CLEAN_UP');

export const SCREENS_CLEAR_DATA_LOGOUT = createAction(
  'SCREENS_CLEAR_DATA_LOGOUT'
);

export const SET_ACTIVE_TAB = createAction('SET_ACTIVE_TAB');
export const SET_ACTIVE_SCREEN = createAction('SET_ACTIVE_SCREEN');

export const fetchScreens = ({ screenId = '', siteId }) => {
  return async (dispatch, getState) => {
    dispatch(SCREENS_FETCH_DATA_INIT());
    if (screenId) {
      let screenData;
      try {
        const res = await axios.post(
          `${url}/screen/get`,
          {
            id: screenId,
            siteId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        screenData = res.data.data;
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SCREENS_FETCH_DATA_FAIL({ error }));
      }

      const screen = { ...screenData, id: screenId };
      log(screen);
      log(screenData);
      return dispatch(
        SCREENS_FETCH_DATA_SUCCESS({
          list: getState().screens.list,
          screen,
        })
      );
    }

    const { uid } = localStorage.getItem('uid');

    let screen;

    try {
      const res = await axios.get(`${url}/screen/`, {
        params: {
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      screen = res.data.data;
      log(screen);
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(SCREENS_FETCH_DATA_FAIL({ error }));
    }

    const screenData = screen
      ? Object.entries(screen).map(([key, value]) => ({
          id: key,
          ...value,
        }))
      : [];

    return dispatch(
      SCREENS_FETCH_DATA_SUCCESS({
        list: screenData.filter((screen) => screen.id !== uid),
        screen: getState().screens.screen,
      })
    );
  };
};

export const deleteScreen = (id) => {
  return async (dispatch, getState) => {
    dispatch(SCREENS_DELETE_SCREEN_INIT());

    try {
      await axios.delete(`${url}/screen/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREENS_DELETE_SCREEN_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The screen was deleted.');
    return dispatch(SCREENS_DELETE_SCREEN_SUCCESS({ id }));
  };
};

export const createScreen = ({
  name,
  description,
  siteId,
  siteName,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(SCREENS_CREATE_SCREEN_INIT());

    const screen = {
      name,
      description,
      siteId,
      siteName,
      modifiedAt,
      modifiedBy,
    };

    try {
      await axios.post(`${url}/screen/create`, screen, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREENS_CREATE_SCREEN_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'screen created successfully');
    return dispatch(SCREENS_CREATE_SCREEN_SUCCESS({ screen }));
  };
};

export const modifyScreen = ({
  name,
  description,
  siteId,
  siteName,
  modifiedAt,
  modifiedBy,
  id,
}) => {
  return async (dispatch, getState) => {
    dispatch(SCREENS_MODIFY_SCREEN_INIT());

    const screenData = {
      name,
      description,
      siteId,
      siteName,
    };

    try {
      await axios.post(`${url}/screen/${id}`, screenData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SCREENS_MODIFY_SCREEN_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Screen updated successfully');

    return dispatch(SCREENS_MODIFY_SCREEN_SUCCESS({ screen: screenData, id }));
  };
};

export const screensCleanUp = () => (dispatch) => dispatch(SCREENS_CLEAN_UP());

// added by me
export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch(SET_ACTIVE_TAB(tab));
  };
};

export const setActiveScreen = (screen, cb) => {
  return (dispatch) => {
    dispatch(SET_ACTIVE_SCREEN(screen));
    cb();
  };
};
