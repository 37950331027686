import { Tabs } from 'antd';
// import Feedbacks from 'pages/Feedbacks';
import Information from 'pages/Information';
import InformationCategories from 'pages/InformationCategories';
import MediaCategories from 'pages/MediaCategories';
import MediaLibrary from 'pages/MediaLibrary';
import Pois from 'pages/Pois';
import { log } from 'utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setActiveTab } from 'state/actions/tab';
import './SiteInfo.css';
import ScreenSettings from 'pages/ScreenSettings';
import ConfigVariable from 'pages/ConfigVariable';
import ScreenConfiguration from 'pages/ScreenConfiguration';
import FileManagement from 'pages/FileManagement';
import Screens from 'pages/Screens';
import Maps from 'pages/Maps';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import {
  BoxIcon,
  GalleryIcon,
  InfoIcon,
  MessageIcon,
} from 'assets/icon/dynamic';

const { TabPane } = Tabs;

function SiteInfo({ history, setCollapsed }) {
  const { siteId, siteName } = useParams();
  const [selected] = useState();
  const [grantAccess, setGrantAccess] = useState();

  const dispatch = useDispatch();
  const { tab: activeTab, userData, sitesList } = useSelector((state) => ({
    tab: state.tab.tab,
    userData: state.auth.userData,
    sitesList: state.sites.list,
  }));

  const [hoverTab, setHoverTab] = useState(activeTab);

  useEffect(() => {
    const acc = userData.role !== 'superadmin' && userData.roleAccess
      ? userData.roleAccess.access.find((userAccess) => userAccess.siteId === siteId)
      : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
      userData.access[0];
    setGrantAccess(acc ?? userData.access[0]);
  }, [siteId, userData]);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { menu: siteName, link: `/${siteId}/information/${siteName}` },
      ])
    );
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selected) {
      dispatch(setActiveTab({ tab: selected }));
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    setActiveTab(activeKey);
    dispatch(setActiveTab({ tab: activeKey }));
  };

  const siteDescription =
    sitesList &&
    // eslint-disable-next-line
    sitesList.map((site) => {
      if (site.name === siteName) {
        return site.description;
      }
    });

  const validateMenu = (crud) => {
    if (typeof crud !== 'object') return crud;
    return Object.values(crud).includes(true);
  };

  return (
    <div className="siteinfo">
      <div className="siteinfo-nav p-20">
        <div>
          {siteName && siteName.length > 30 ? (
            <h3 className="title">{siteName.substr(0, 30)}</h3>
          ) : (
            <h3 className="title">{siteName}</h3>
          )}
          <span>{siteDescription && siteDescription}</span>
        </div>
        {/* <button onClick={handleBack} className="button-primary">
          Back
        </button> */}
      </div>

      {userData && userData.role === 'superadmin' ? (
        <Tabs
          activeKey={activeTab}
          onTabClick={handleChangeTab}
        >
          <TabPane
            onMouseOver={() => setHoverTab('media')}
            onMouseLeave={() => setHoverTab('')}
            tab={
              <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
                <GalleryIcon
                  color={
                    activeTab === 'media' || hoverTab === 'media'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Media</span>
              </div>
            }
            key={activeTab === 'mediaCategory' ? 'mediaCategory' : 'media'}
          >
            <div className="tab-content">
              {activeTab === 'media' && <MediaLibrary history={history} />}
              {activeTab === 'mediaCategory' && <MediaCategories />}
            </div>
          </TabPane>
          <TabPane
            tab={
              <div
                onClick={(e) => e.preventDefault()}
                className="siteinfo-tab"
                onMouseOver={() => setHoverTab('poi')}
                onMouseLeave={() => setHoverTab('')}
              >
                <BoxIcon
                  color={
                    activeTab === 'poi' || hoverTab === 'poi'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Tenants</span>
              </div>
            }
            key='poi'
          >
            <div className="tab-content">
              {activeTab === 'poi' && <Pois history={history} />}
            </div>
          </TabPane>

          <TabPane
            tab={
              <div
                onClick={(e) => e.preventDefault()}
                className="siteinfo-tab"
                onMouseOver={() => setHoverTab('information')}
                onMouseLeave={() => setHoverTab('')}
              >
                <InfoIcon
                  color={
                    activeTab === 'information' || hoverTab === 'information'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Information</span>
              </div>
            }
            key={
              activeTab === 'informationCategory'
                ? 'informationCategory'
                : 'information'
            }
          >
            <div className="tab-content">
              {activeTab === 'information' && <Information history={history} />}
              {activeTab === 'informationCategory' && <InformationCategories />}
            </div>
          </TabPane>

          <TabPane
            tab={
              <div
                onClick={(e) => e.preventDefault()}
                className="siteinfo-tab"
                onMouseOver={() => setHoverTab('screen')}
                onMouseLeave={() => setHoverTab('')}
              >
                <MessageIcon
                  color={
                    activeTab === 'screen' || hoverTab === 'screen'
                      ? '#0851B5'
                      : '#9E9E9E'
                  }
                  size={18}
                />
                <span>Screen</span>
              </div>
            }
            key={
              activeTab === 'screenConfiguration'
                ? 'screenConfiguration'
                : activeTab === 'configVariable'
                  ? 'configVariable'
                  : activeTab === 'screenSetting'
                    ? 'screenSetting'
                    : activeTab === 'fileManagement'
                      ? 'fileManagement'
                      : 'screens'
            }
          >
            <div className="tab-content">
              {activeTab === 'screens' && <Screens history={history} />}
              {activeTab === 'screenSetting' && (
                <ScreenSettings handleChangeTab={handleChangeTab} />
              )}
              {activeTab === 'configVariable' && (
                <ConfigVariable handleChangeTab={handleChangeTab} />
              )}
              {activeTab === 'screenConfiguration' && (
                <ScreenConfiguration handleChangeTab={handleChangeTab} />
              )}
              {activeTab === 'fileManagement' && (
                <FileManagement handleChangeTab={handleChangeTab} />
              )}
            </div>
          </TabPane>
        </Tabs>
      ) : userData.role === 'user' || userData.role === 'admin' ? (
        <Tabs
          defaultActiveKey={activeTab}
          onTabClick={handleChangeTab}
          className="p20"
        >
          {grantAccess && validateMenu(grantAccess.media) && (
            <TabPane
              onMouseOver={() => setHoverTab('media')}
              onMouseLeave={() => setHoverTab('')}
              tab={
                <div onClick={(e) => e.preventDefault()} className="siteinfo-tab">
                  <GalleryIcon
                    color={
                      activeTab === 'media' || hoverTab === 'media'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Media</span>
                  {/* <DownOutlined /> */}
                </div>
                // </Dropdown>
              }
              key={activeTab === 'mediaCategory' ? 'mediaCategory' : 'media'}
            >
              <div className="tab-content">
                {activeTab === 'media' && <MediaLibrary history={history} />}
                {activeTab === 'mediaCategory' && <MediaCategories />}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.poi) && (
            <TabPane
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                  onMouseOver={() => setHoverTab('poi')}
                  onMouseLeave={() => setHoverTab('')}
                >
                  <BoxIcon
                    color={
                      activeTab === 'poi' || hoverTab === 'poi'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Tenants</span>
                </div>
              }
              key='poi'
            >
              <div className="tab-content">
                {activeTab === 'poi' && <Pois history={history} />}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.information) && (
            <TabPane
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                  onMouseOver={() => setHoverTab('information')}
                  onMouseLeave={() => setHoverTab('')}
                >
                  <InfoIcon
                    color={
                      activeTab === 'information' || hoverTab === 'information'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Information</span>
                </div>
              }
              key={
                activeTab === 'informationCategory'
                  ? 'informationCategory'
                  : 'information'
              }
            >
              <div className="tab-content">
                {activeTab === 'information' && <Information history={history} />}
                {activeTab === 'informationCategory' && <InformationCategories />}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.screen) && (
            <TabPane
              tab={
                <div
                  onClick={(e) => e.preventDefault()}
                  className="siteinfo-tab"
                  onMouseOver={() => setHoverTab('screen')}
                  onMouseLeave={() => setHoverTab('')}
                >
                  <MessageIcon
                    color={
                      activeTab === 'screen' || hoverTab === 'screen'
                        ? '#0851B5'
                        : '#9E9E9E'
                    }
                    size={18}
                  />
                  <span>Screen</span>
                </div>
              }
              key={
                activeTab === 'screenConfiguration'
                  ? 'screenConfiguration'
                  : activeTab === 'configVariable'
                    ? 'configVariable'
                    : activeTab === 'screenSetting'
                      ? 'screenSetting'
                      : activeTab === 'fileManagement'
                        ? 'fileManagement'
                        : 'screens'
              }
            >
              <div className="tab-content">
                {activeTab === 'screens' && <Screens history={history} />}
                {activeTab === 'screenSetting' && (
                  <ScreenSettings handleChangeTab={handleChangeTab} />
                )}
                {activeTab === 'configVariable' && (
                  <ConfigVariable handleChangeTab={handleChangeTab} />
                )}
                {activeTab === 'screenConfiguration' && (
                  <ScreenConfiguration handleChangeTab={handleChangeTab} />
                )}
                {activeTab === 'fileManagement' && (
                  <FileManagement handleChangeTab={handleChangeTab} />
                )}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.map) && (
            <TabPane
              tab={
                <>
                  <i className="feather-map" /> Wayfinding
                </>
              }
              key="wayfinding"
            >
              <div className="tab-content">
                {activeTab === 'wayfinding' && <Maps />}
              </div>
            </TabPane>
          )}


        </Tabs>
      ) : null}

      {/* <div className="bottom-nav">
        <button
          onClick={handleBack}
          className="button-primary siteinfo-back-button"
        >
          Back
        </button>
      </div> */}
    </div>
  );
}

export default SiteInfo;
